@import "~bootstrap/scss/bootstrap";

$font-stack: "Gangster", sans-serif;
$background-color-dark: #1f1f1f;
$background-color-light: #ffffff;

@font-face {
  font-family: "Gangster";
  src: local("Gangster"), url(./assets/gangstergrotesk-bold.woff) format("woff");
}

.floating-div {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2000;
  transition: transform 0.2s; /* Animation */
  h5 {
    padding: 10px;
    margin: 0;
    color: black;
  }
}

.floating-div:hover {
  transform: scale(1.05);
}

.reveal {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.reveal-after {
  content: " ";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 2s ease;
}

a {
  cursor: pointer;
}
body.dark-mode {
  font: 100% $font-stack;
  background-color: $background-color-light;
  color: $background-color-dark;
  a {
    color: black;
    font-weight: 500;
  }
  a:hover {
    color: black;
  }
  .modeIcon {
    background-image: url(assets/moon.svg);
  }
  .black-container {
    background-color: #ffffff;
  }
  .reveal-after {
    background: #fff;
  }
}

.fadeInStart {
  opacity: 0;
  transition: 2s opacity;
}

.fadeInEnd {
  opacity: 0;
  transition: 2s opacity;
}

a,
.nav-link {
  cursor: pointer;
}

body {
  font: 100% $font-stack;
  background-color: $background-color-dark;
  color: $background-color-light;
  a {
    color: white;
    font-weight: 700;
  }
  a:hover {
    color: white;
  }
  .modeIcon {
    background-image: url(assets/sun.svg);
  }
  .row {
    margin-bottom: 15px;
  }
  .reveal-after {
    background: #1f1f1f;
  }
}

html {
  font-size: 18px;
}

.spacer {
  padding-top: 5rem;
}

.hiddenDiv {
  display: none;
}

.center {
  text-align: center;
}

.full-width {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.hide {
  display: none;
}

.myDIV:hover + .hide {
  display: block;
}

.accordion-lines {
  padding-top: 2em;
  padding-bottom: 2rem;
}

.mainContainer {
  position: relative;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  margin-bottom: 3rem;
  height: 500px;
}

.mainImg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-size: cover;
}

.pageMainImg {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.stats {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.container-slide {
  visibility: hidden;
}

.img-container {
  position: relative;
  overflow: hidden;
}

p {
  line-height: 1.7rem;
  font-family: sans-serif;
}

.p-small {
  font-size: 12px;
}

.absolute {
  position: absolute;
  word-break: break-word;
}

.largeText {
  color: white;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 5rem;
}

.textTop {
  position: absolute;
  top: 0;
}
.textBottom {
  position: absolute;
  bottom: 0;
}

.image-col {
  padding: 2rem;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
}
h2 {
  font-size: 1.5rem;
  font-weight: 500;
}

.inlineImg {
  max-width: 50px;
}

.crop-image {
  width: 90%;
  height: 70%;
  top: 15%;
  left: 5%;
}

.mainIcon {
  width: 50px;
  height: 50px;
}

.header {
  padding-top: 20px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 5rem;
    font-weight: 500;
  }
  h2 {
    font-size: 3rem;
    font-weight: 500;
  }
  .largeText {
    font-size: 7rem;
  }
  .inlineImg {
    max-width: 100px;
  }
  .crop-image {
    width: 70%;
    height: 70%;
    top: 15%;
    left: 15%;
  }
  .mainContainer {
    height: 700px;
  }
  .mainIcon {
    width: 100px;
    height: 100px;
  }
  .floating-div {
    position: fixed;
    bottom: 20px;
    left: 15px;
    width: 200px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid black;
    transform: rotate(-20deg);
    transition: transform 0.2s; /* Animation */
  }
  .floating-div:hover {
    transform: scale(1.05) rotate(-20deg);
  }
}
